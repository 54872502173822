import * as THREE from 'three';
import {
  Raycaster,
  Vector2,
  AmbientLight,
  AxesHelper,
  DirectionalLight,
  GridHelper,
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
  MeshLambertMaterial,
  Mesh
} from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

import {IFCLoader} from 'web-ifc-three/IFCLoader.js';
import {
  IFCSPACE,
  IFCWALL,
  IFCBEAM,
  IFCWALLSTANDARDCASE,
  IFCCURTAINWALL,
  IFCSLAB,
  IFCFURNISHINGELEMENT,
  IFCDOOR,
  IFCDOORSTANDARDCASE,
  IFCWINDOW,
  IFCSTAIR,
  IFCPLATE,
  IFCMEMBER,
  IFCOPENINGELEMENT,
} from 'web-ifc';

import {acceleratedRaycast,computeBoundsTree,disposeBoundsTree} from 'three-mesh-bvh';

export class Instance {
  constructor ({
                 // 渲染模型的容器（id或DOM对象）
                 ele,
                 // 场景的宽度（默认1000像素）
                 width = 1000,
                 // 场景的高度（默认500像素）
                 height = 500,
                 // 加载进度显示回调函数
                 onProgressCallback,
                 // 显示加载遮罩层
                 startLoading,
                 stopLoading,
                 // 拾取到元素对象时的回调函数（比如：进一步查询属性数据并显示属性弹窗）
                 onPickCallback
               }) {
    if (!ele) {
      throw '渲染three canvas容器的重要参数(ele)不能为空！';
    }
    this.ele = ele;
    this.width = width;
    this.height = height;
    this.onProgressCallback = onProgressCallback;
    this.startLoading = startLoading;
    this.stopLoading = stopLoading;
    this.onPickCallback = onPickCallback;

    // 场景
    this.scene = null;
    // 网格模型对象Mesh
    this.mesh = null;
    // 渲染场景的canvas元素
    this.threeCanvas = null;
    // 渲染器
    this.renderer = null;
    // 相机
    this.camera = null;
    // 控制器
    this.controls = null;
    // 创建web-ifc-three渲染器
    this.ifcLoader = new IFCLoader();
    // 光线投射，用于进行鼠标拾取（在三维空间中计算出鼠标移过了什么物体）
    this.raycaster = new Raycaster();
    // 为了优化应用程序，Raycaster 将只从它遇到的第一个对象中获取信息。
    this.raycaster.firstHitOnly = true;
    // 鼠标的桌面二维坐标
    this.mouse = new Vector2();
    // 渲染动画ID，为了方便关闭动画
    this.animationFrameId = null;
    // 已加载的ifc模型
    this.preIfcModel = null;
    // 图层类别名称
    this.categories = {
      IFCSPACE,
      IFCWALL,
      IFCBEAM,
      IFCWALLSTANDARDCASE,
      IFCCURTAINWALL,
      IFCSLAB,
      IFCFURNISHINGELEMENT,
      IFCDOOR,
      IFCDOORSTANDARDCASE,
      IFCWINDOW,
      IFCSTAIR,
      IFCPLATE,
      IFCMEMBER,
      IFCOPENINGELEMENT,
    };

    // MeshLambertMaterial是一种暗淡的非光泽表面的材质，没有镜面高光，并且会对光源做出反应。
    // translucenceMat对象在当前文件中的作用是：创建几乎透明的模型子集时使用。
    // 【重点】几何子集是通过材料识别的，也就是说用材料A（变量）创建了一个墙的子集，然后试图用同样的材料A创建另一个墙的子集，第二个墙将被添加到第一个墙的子集中。
    this.translucenceMat = new MeshLambertMaterial({
      transparent: true,
      opacity: 0.03,
      color: 0xFFFFFF,
      depthTest: false, // depthTest=false，这样物体从任何视角都可以看到
    });

    // 拾取模型时的高亮效果
    this.preselectMat = new MeshLambertMaterial({
      transparent: true,
      opacity: 0.7,
      color: 0xff88ff,
      depthTest: false, // depthTest=false，这样物体从任何视角都可以看到
    });

    // 形象进度-已开工效果
    this.visualScheduleStartMat = new MeshLambertMaterial({
      transparent: true,
      opacity: 0.7,
      color: 0xebae0a,
      depthTest: false, // depthTest=false，这样物体从任何视角都可以看到
    });
    // 形象进度-已完工效果
    this.visualScheduleCompletedMat = new MeshLambertMaterial({
      transparent: true,
      opacity: 0.7,
      color: 0x37ddeb,
      depthTest: false, // depthTest=false，这样物体从任何视角都可以看到
    });
    // 当前选中元素（高亮的对象）
    this.preselectModel = {modelID: -1,expressId: -1};

    this.initThree()
  }

  /**
   * 场景
   */
  initScene () {
    // 创建Three.js场景
    this.scene = new THREE.Scene();
    // this.scene.background = new THREE.Color(0x8cc7de);
    this.scene.background = new THREE.Color(0xffffff);
  }

  /**
   * 为场景添加光线
   */
  initLights () {
    const directionalLight1 = new THREE.DirectionalLight(0xffeeff,0.8);
    directionalLight1.position.set(1,1,1);
    // 告诉平行光需要开启阴影投射
    // directionalLight1.castShadow = true;
    this.scene.add(directionalLight1);

    const directionalLight2 = new THREE.DirectionalLight(0xffffff,0.8);
    directionalLight2.position.set(-1,0.5,-1);
    // 告诉平行光需要开启阴影投射
    // directionalLight2.castShadow = true;
    this.scene.add(directionalLight2);

    const ambientLight = new THREE.AmbientLight(0xffffee,0.25);
    this.scene.add(ambientLight);
  }

  /**
   * 配置IFCLoader
   */
  async setupIFCLoader () {
    await this.ifcLoader.ifcManager.setWasmPath('./');

    // 渲染的时候是否显示空间图层
    // 设置可选类别图层
    await this.ifcLoader.ifcManager.parser.setupOptionalCategories({
      [IFCSPACE]: false,
      [IFCOPENINGELEMENT]: false
    });

    await this.ifcLoader.ifcManager.applyWebIfcConfig({
      USE_FAST_BOOLS: true
    });

    // 使用 Garrett Johnson 加快拾取速度（大型IFC模型）
    await this.ifcLoader.ifcManager.setupThreeMeshBVH(
      computeBoundsTree,
      disposeBoundsTree,
      acceleratedRaycast
    );
  }

  /**
   * 创建相机对象
   */
  initCamera () {
    this.camera = new THREE.PerspectiveCamera(45,window.innerWidth / window.innerHeight,0.1,1000);
    // camera.position.set(0, 40, 100);
    this.camera.lookAt(new THREE.Vector3(0,0,0));
  }

  /**
   * 创建渲染器
   */
  initRender () {
    // 创建Three.js渲染器 antialias: false, antialias 抗锯齿 || alpha 控制默认的透明值
    this.renderer = new THREE.WebGLRenderer({antialias: true,alpha: true});
    this.renderer.setSize(this.width,this.height);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.threeCanvas = (this.ele instanceof HTMLElement) ? this.ele : document.getElementById(this.ele);
    this.threeCanvas.appendChild(this.renderer.domElement);
    this.threeCanvas.ondblclick = this.pick;
  }

  /**
   * 辅助工具
   */
  initHelper () {
    if (!this.camera) {
      throw '请先创建相机对象（camera）！';
    }
    // grids辅助网格
    this.scene.add(new GridHelper(50,30));

    // Axes辅助轴线
    // axes轴（右手坐标系）：红色代表 X 轴. 绿色代表 Y 轴. 蓝色代表 Z 轴.
    const axes = new AxesHelper();
    axes.material.depthTest = false;
    axes.renderOrder = 1;
    this.scene.add(axes);

    // 相机辅助线
    this.scene.add(new THREE.CameraHelper(this.camera));
  }

  /**
   * 创建控制器
   */
  initControls () {
    if (!this.camera) {
      throw '请先创建相机对象（camera）！';
    }
    if (!this.renderer) {
      throw '请先创建渲染器（renderer）！';
    }

    this.controls = new OrbitControls(this.camera,this.renderer.domElement);
    // this.controls.addEventListener('change', this.render); // addEventListener 第二个参数 是一个方法，不能直接把renderer写进去 || 不能写成 render()

    /**
     * 设置控制器角度
     * phi 是俯仰角（0 俯视 --> 大于0，趋于仰视）
     * theta 是水平旋转角（小于0，逆时针转；大于0，顺时针转）
     * distance 是距离
     */
    this.controls.setAngle = (phi,theta,distance) => {
      var r = distance || this.controls.object.position.distanceTo(this.controls.target);

      var x = r * Math.cos(phi - Math.PI / 2) * Math.sin(theta) + this.controls.target.x;
      var y = r * Math.sin(phi + Math.PI / 2) + this.controls.target.y;
      var z = r * Math.cos(phi - Math.PI / 2) * Math.cos(theta) + this.controls.target.z;

      this.controls.object.position.set(x,y,z);
      this.controls.object.lookAt(this.controls.target);
    };

    this.controls.enableDamping = true; // 使动画循环使用时阻尼或自转 意思是否有惯性
    // this.controls.autoRotate = true; // 自动旋转
    this.controls.rotateSpeed = 0.5; // 旋转速度(ORBIT的旋转速度，鼠标左键)，默认1
    this.controls.panSpeed = 0.5; // 位移速度(ORBIT的位移速度，鼠标右键)，默认1
    // 请注意，可以通过将 polarAngle 或者 azimuthAngle 的min和max设置为相同的值来禁用单个轴， 这将使得垂直旋转或水平旋转固定为所设置的值。
    // 你能够垂直旋转的角度的上、下限，范围是0到Math.PI，其默认值为Math.PI。
    // Tips：如下图设置，是限制相机向下旋转，但是可以向上旋转
    this.controls.maxPolarAngle = Math.PI / 2;
    this.controls.minPolarAngle = 0;
    this.controls.target.set(0,0,0);

    // controls.minDistance = 1;// 设置相机距离原点的最近距离
    this.controls.maxDistance = 200;// 设置相机距离原点的最远距离
  }

  /**
   * 开启多进程
   */
  async setupMultiThreading () {
    await this.ifcLoader.ifcManager.useWebWorkers(true,"/ifc/IFCWorker.js"); // Uncaught SyntaxError: Unexpected token '<' (at IFCWorker.js:1:1)
    await this.ifcLoader.ifcManager.setWasmPath("./");
  }

  /**
   * 获取模型的高度，并设置相机初始视角（也初始化控制器的视角）
   */
  setCameraView () {
    // 获取模型边界框
    const boundingBox = new THREE.Box3().setFromObject(this.mesh);
    // 获取中心位置
    const center = boundingBox.getCenter(new THREE.Vector3());
    // 获取模型高度
    const height = boundingBox.max.y - boundingBox.min.y;

    // 计算相机位置
    // const cameraPosition = new THREE.Vector3(0, height / 2.8, height * 2); // x, y, z
    // 计算相机目标点
    const target = new THREE.Vector3(center.x,center.y,center.z);

    // 计算模型绕 y 轴逆时针旋转 45 度的四元数。
    const angle = Math.PI / 4; // 将相机向左偏转 45 度
    const axis = new THREE.Vector3(0,1,0); // 绕 y 轴旋转
    // const quaternion = new THREE.Quaternion().setFromAxisAngle(axis, angle);
    // mesh.applyQuaternion(quaternion);

    // mesh.rotateY(angle); // 模型本身旋转，这效果与上面注释的两行代码一样

    // 将模型偏移原先坐标位置，可能会引发一系列的问题
    // const offset = center.clone().negate(); // 计算模型在屏幕中心的偏移量，即将模型平移到屏幕中心的向量。
    // mesh.position.add(offset); // 将模型平移到屏幕中心。

    // let distance = boundingBox.getBoundingSphere(new THREE.Sphere()).radius;
    // 用于改变controler的水平、垂直角度，第三个参数控制距离
    this.controls.setAngle(1.4,-0.8,height * 1.5);
    this.controls.target.copy(center); // 将视角的目标点移到包围盒的中心点
    this.controls.update(); // 更新视角的位置

    // 设置相机位置和目标点
    // this.camera.position.set(cameraPosition);// 设置相机位置 —— 这里好像没有发挥作用
    // this.camera.position.copy(cameraPosition); // 将源摄像机的属性复制到新摄像机中。—— 这个去掉导致会看不到模型
    this.camera.lookAt(target); // camera.lookAt 与 orbitcontrol冲突不能使用，要用controls.target代替 //controls.target = new THREE.Vector3(0,-100,0);
    // this.camera.rotation.y = angle; //物体的均匀从左到又平移可以用相机旋转Y轴来实现 —— 这里好像没有发挥作用
    // this.camera.fov = 100; // 摄像机视锥体垂直视野角度，从视图的底部到顶部，以角度来表示。默认值是50。这个值是用来确定相机前方的垂直视角，角度越大，我们能够查看的内容就越多。
  }

  /**
   * 显示模型加载进度
   */
  setupProgressNotification () {
    this.ifcLoader.ifcManager.setOnProgress((event) => {
      const progress = Math.trunc(event.loaded / event.total * 100);
      if (typeof this.onProgressCallback == 'function') {
        this.onProgressCallback(progress)
      }
    });
  }

  /**
   * 控制器动画
   */
  animate = () => {// 通过箭头函数解决requestAnimationFrame的this作用域问题
    this.controls.update();
    this.renderer.render(this.scene,this.camera);
    this.animationFrameId = requestAnimationFrame(this.animate);  // 使用requestAnimationFrame可以让浏览器根据自身的渲染节奏调整动画的帧率，从而避免过度渲染，优化three.js渲染性能
  }

  /**
   * 停止requestAnimationFrame动画
   */
  stopAnimationFrame () {
    window.cancelAnimationFrame(this.animationFrameId);
  }

  // 获取该类型的所有元素
  async getAll (category,verbose) {
    if (this.preIfcModel) {
      // 第三个参数：true是代表返回对象数组，false代表返回expressId数组
      return this.ifcLoader.ifcManager.getAllItemsOfType(this.preIfcModel.modelID,category,verbose);
    } else {
      return []
    }
  }

  getAllIds () {
    if (this.preIfcModel) {
      return Array.from(
        new Set(this.preIfcModel.geometry.attributes.expressID.array),
      );
    } else {
      return []
    }
  }

  /**
   * 计算鼠标在屏幕上的位置上的模型（数组）
   */
  cast = (event) => {
    if (!this.camera) {
      throw '请先创建相机对象（camera）！';
    }
    if (!this.mouse) {
      throw '请先创建桌面二维坐标对象Vector2（mouse）！';
    }
    if (!this.raycaster) {
      throw '请先创建光线投射对象（Raycaster）！';
    }

    // 计算鼠标在屏幕上的位置
    const bounds = this.threeCanvas.getBoundingClientRect();

    const x1 = event.clientX - bounds.left;
    const x2 = bounds.right - bounds.left;
    this.mouse.x = (x1 / x2) * 2 - 1;

    const y1 = event.clientY - bounds.top;
    const y2 = bounds.bottom - bounds.top;
    this.mouse.y = -(y1 / y2) * 2 + 1;

    // 将其放置在指向鼠标的相机上
    this.raycaster.setFromCamera(this.mouse,this.camera);

    // 投射射线
    // intersectObjects方法用于检查射线和物体之间的所有交叉点（包含或不包含后代），交叉点返回按距离排序，最接近的为第一个，返回一个交叉点对象数组。
    if (this.preIfcModel) {
      return this.raycaster.intersectObject(this.preIfcModel);
    } else {
      return [];
    }
  }

  /**
   * 元素高亮
   */
  highlight = (event,found) => {
    // found是根据鼠标点击的位置进行three.js碰撞检测（即：射线与被检测对象相交）获取到的模型对象
    if (!found) {
      found = this.cast(event)[0];
    }
    if (found) {
      const index = found.faceIndex;
      const geometry = found.object.geometry;
      const expressId = this.ifcLoader.ifcManager.getExpressId(geometry,index);

      if (this.preselectModel.expressId != expressId) {
        // 选中了新的模型时，首先去掉之前其他模型的高亮，然后再高亮当前模型
        this.ifcLoader.ifcManager.removeSubset(this.preselectModel.modelID,this.preselectMat);
        this.preselectModel.modelID = found.object.modelID;
        this.preselectModel.expressId = expressId;

        // 子集是对模型几何形状的提取（通过创建子集对象，附加新的特征）
        this.ifcLoader.ifcManager.createSubset({
          modelID: this.preselectModel.modelID,
          ids: [expressId],
          material: this.preselectMat,// 高亮材质（如果material是undefined，则代表的是原始材料）
          scene: this.scene,
          removePrevious: true
        });

        return true;
      } else {
        // 当前模型高亮时，再次选中，则取消高亮
        this.ifcLoader.ifcManager.removeSubset(this.preselectModel.modelID,this.preselectMat);
        this.preselectModel = {modelID: -1,expressId: -1};
        return false;
      }
    }
    return false;
  }

  /**
   * 鼠标拾取
   */
  pick = async (event) => {
    if (typeof this.startLoading == 'function') {
      this.startLoading('正在查询...')
    }
    window.setTimeout(async () => {
      let founds = this.cast(event);
      if (founds && founds.length > 0) {
        const found = founds[0];
        if (found) {
          const modelID = found.object.modelID;
          const index = found.faceIndex;
          const geometry = found.object.geometry;

          const ifc = this.ifcLoader.ifcManager;
          const expressId = ifc.getExpressId(geometry,index);
          // console.log('expressId', expressId);

          // 通过该数组控制用户双击已隐藏的模型时，不再高亮和查询属性
          // if (subsetExpressIds && subsetExpressIds.length > 0 && !subsetExpressIds.includes(expressId)) {
          //     return false;
          // }
          let isHighlight = this.highlight(event,found) // 高亮
          if (!isHighlight) {
            if (typeof this.stopLoading == 'function') {
              this.stopLoading()
            }
            return false;
          }

          // 直接属性信息（在 IFC 模式中，有两种类型的属性：直接和间接。）
          const props = await ifc.getItemProperties(modelID,expressId);
          // 元素的IFC类型（例如：IFCWALL）
          // const type = await ifc.getIfcType(modelID, expressId);
          // 材质信息
          // const materials = await ifc.getMaterialsProperties(modelID, expressId);

          // const spaces = await ifc.getSpatialStructure(modelID);
          // 类型属性
          // const typeProps = await ifc.getTypeProperties(modelID, expressId);
          // 属性集和数量集
          const propSets = await ifc.getPropertySets(modelID,expressId,true);

          // console.log('元素类型', type);
          // console.log('直接属性', props);
          // console.log('元素材质', materials);
          // console.log('类型属性=', typeProps);
          // console.log('扩展属性=', propSets);
          // 通过回调函数调用vue中的方法，打开构件属性面板
          if (typeof this.onPickCallback == 'function') {
            this.onPickCallback(expressId,props,propSets)
          }
          if (typeof this.stopLoading == 'function') {
            this.stopLoading()
          }
        }
      } else {
        if (typeof this.stopLoading == 'function') {
          this.stopLoading()
        }
      }
    },50)
  }

  /**
   * 释放内存
   */
  async releaseMemory () {
    if (this.preIfcModel) {
      // await ifcLoader.ifcManager.dispose();
      // 尚不清楚dispose和close的差异
      await this.ifcLoader.ifcManager.close(this.preIfcModel.modelID,this.scene);
      await this.ifcLoader.ifcManager.removeSubset(this.preIfcModel.modelID,this.translucenceMat,'subset-all');
      await this.ifcLoader.ifcManager.removeSubset(this.preIfcModel.modelID,null,'subset-current-wbs');
      // 删除选中时的高亮子集
      await this.ifcLoader.ifcManager.removeSubset(this.preselectModel.modelID,this.preselectMat);
      // 形象进度
      await this.ifcLoader.ifcManager.removeSubset(this.preIfcModel.modelID,this.visualScheduleStartMat,'subset-visual-schedule-started');
      await this.ifcLoader.ifcManager.removeSubset(this.preIfcModel.modelID,this.visualScheduleCompletedMat,'subset-visual-schedule-completed');
      // 释放已经加载的IFC模型
      this.preIfcModel = null;
  /**
   * 初始化
   */
}
}

async initThree () {
    // 初始化Three.js场景
    this.initScene();

    // 初始化默认视角
    this.initCamera();

    // 创建平行光、点光源等光线对象
    this.initLights();

    // 配置IFCLoader
    this.setupIFCLoader();

    // 创建WebGLRenderer并添加到页面上
    this.initRender();

    // 创建网格、轴线、相机助手等辅助工具
    // this.initHelper();

    // 创建轨道控制器（可实现场景用鼠标交互，让场景动起来，控制场景的旋转、平移，缩放）
    this.initControls();

    // 启动多线程（是要处理IFCWorker.js中的任务吗？）
    this.setupMultiThreading();

    // 显示模型的加载进度
    this.setupProgressNotification();

    //Animation loop
    this.animate();

    // window.addEventListener('resize', onWindowResize);
    // window.onclick = (event) => highlight(event);
  }

  /**
   * 加载IFC模型
   * @param url ifc 文件路径
   * @param wbsIfcData wbs和ifc的关系
   * @param callback 回调函数
   */
  async loadIfc (url,wbsIfcData,callback) {
    // subsetExpressIds = []
    this.ifcLoader.load(url,async (ifcModel) => {  // IFC文件地址
      this.mesh = ifcModel.mesh ? ifcModel.mesh : null;

      // this.render();
      this.setCameraView();

      this.preIfcModel = ifcModel

      // let ss = await ifcLoader.ifcManager.getSpatialStructure(preIfcModel.modelID);
      // console.log('空间结构', ss);
      // IFCPROJECT
      //      -IFCSITE
      //          -IFCBUILDING
      //              -IFCBUILDINGSTOREY
      //                  -IFCSLAB：盖板、楼板
      //                  -IFCBEAM：梁
      //                  -IFCCOLUMN：柱
      //                  -IFCWALL：墙
      //                  -IFCWALLSTANDARDCASE：标准墙
      //                  -IFCDOOR：门
      //                  -IFCWINDOW：窗
      //                  -IFCSTAIR：楼梯

      // 解析需要显示的元素ID（说明：如果wbsIfcData参数为空，则不进行任何特殊处理，直接显示完整IFC模型）
      if (wbsIfcData && wbsIfcData.length > 0) {
        let showIds = []
        for (const dto of wbsIfcData) {
          showIds.push(parseInt(dto['expressId']))
        }
        if (showIds.length > 0) {
          // subsetExpressIds = showIds

          // 【方案一】隐藏原始模型，创建透明的网格
          // preIfcModel.visible = false;
          // const modelCopy = new Mesh(
          //     preIfcModel.geometry,
          //     new MeshLambertMaterial({
          //         transparent: true,
          //         opacity: 0.1,
          //         color: 0x77aaff,
          //     })
          // );
          // scene.add(modelCopy);

          // 【方案二】删除原始模型，创建透明的子集
          // 通过创建两次子集实现突出显示当前分部分项，
          // 第一个子集几乎全透明，第二个子集用来显示当前分部分项工程关联的模型
          this.preIfcModel.removeFromParent();
          this.ifcLoader.ifcManager.createSubset({
            modelID: this.preIfcModel.modelID,
            ids: this.getAllIds(),
            applyBVH: true, // BVH算法来加速光线投射并支持快速的3D空间查询
            material: this.translucenceMat,// 半透明
            scene: this.scene,
            removePrevious: true,
            customID: 'subset-all',
          });

          this.ifcLoader.ifcManager.createSubset({
            modelID: this.preIfcModel.modelID,
            // ids: this.getAllIds(),
            ids: showIds,
            applyBVH: true,// BVH算法来加速光线投射并支持快速的3D空间查询
            scene: this.scene,
            removePrevious: true,
            customID: 'subset-current-wbs',
          });
        }
      } else {
        this.scene.add(this.mesh || ifcModel); // 将IFC模型添加到Three.js场景中
      }

      if (typeof callback == 'function') {
        callback()
      }
    });
  }

  /**
   * 遍历解析元素扩展属性中的wbs编码并保存元素与wbs的关系
   */
  async parseWbsCode (prjId,ifcUrl,propName,loadingEle,callback) {
    let start = new Date();
    let submitData = []// 用于提交到后台保存的关系记录
    let totalCount = 0// 记录总共解析到的元素个数，用于最终显示给用户看
    const allCategories = Object.values(this.categories);
    for (let i = 0; i < allCategories.length; i++) {
      let items = await
        this.getAll(allCategories[i],true);
      if (items && items.length > 0) {
        for (const item of items) {
          // TODO 先从直接属性中查询，如果查不到再从扩展属性中查询
          // let itemProperties = await ifcLoader.ifcManager.getItemProperties(preIfcModel.modelID, item.expressID);
          // console.log('直接属性', itemProperties);

          let propSets = await
            this.ifcLoader.ifcManager.getPropertySets(this.preIfcModel.modelID,item.expressID,true);
          if (propSets && propSets.length > 0) {
            totalCount += propSets.length;
            loadingEle.setText('正在解析中，目前已解析到' + totalCount + '个扩展属性。');
            for (const propSet of propSets) {
              let properties = propSet.HasProperties
              if (properties && properties.length > 0) {
                // console.log(properties);
                for (const prop of properties) {
                  if (prop && prop.Name) {
                    if (prop.NominalValue && prop.NominalValue.value) {
                      // console.log(prop.Name.value + '=' + prop.NominalValue.value);
                      if (propName == prop.Name.value) {
                        submitData.push({
                          prjId: prjId,
                          ifcUrl: ifcUrl,
                          wbsCode: prop.NominalValue.value,
                          expressId: item.expressID
                        })
                      }
                    }
                  }
                }
              }
              properties = null
            }
          }
          propSets = null
        }
      }
      items = null
    }

    if (typeof callback == 'function') {
      let end = new Date();
      let minutes = Math.floor((end - start) / (60 * 1000));
      callback(submitData,totalCount,minutes)
    }
  }

  /**
   * 形象进度
   *
   * @param url ifc 文件路径
   * @param wbsIfcData wbs和ifc的关系
   * @param callback 回调函数
   */
  async visualSchedule (url,wbsIfcData,callback) {
    // subsetExpressIds = []
    this.ifcLoader.load(url,async (ifcModel) => {  // IFC文件地址
        this.mesh = ifcModel.mesh ? ifcModel.mesh : null;

        this.setCameraView();

        this.preIfcModel = ifcModel

        // 解析需要显示的元素ID（说明：如果wbsIfcData参数为空，则不进行任何特殊处理，直接显示完整IFC模型）
        if (wbsIfcData && wbsIfcData.length > 0) {
          let visualScheduleStartedIds = [] // 已开工
          let visualScheduleCompletedIds = [] // 已完工
          let visualScheduleNotStartedIds = [] // 未开工 （默认 模型透明）
          for (const dto of wbsIfcData) {
            // 形象进度状态
            let visualScheState = dto['visualScheState']
            if (visualScheState == 1) {
              // 未开工
              visualScheduleNotStartedIds.push(parseInt(dto['expressId']))
            } else if (visualScheState == 2) {
              // 已开工
              visualScheduleStartedIds.push(parseInt(dto['expressId']))
            }
            else if (visualScheState == 3) {
              // 已完工
              visualScheduleCompletedIds.push(parseInt(dto['expressId']))
            }
            else {
              visualScheduleNotStartedIds.push(parseInt(dto['expressId']))
            }
          }

          // 【方案一】隐藏原始模型，创建透明的网格
          // preIfcModel.visible = false;
          // const modelCopy = new Mesh(
          //     preIfcModel.geometry,
          //     new MeshLambertMaterial({
          //         transparent: true,
          //         opacity: 0.1,
          //         color: 0x77aaff,
          //     })
          // );
          // scene.add(modelCopy);

          // 【方案二】删除原始模型，创建透明的子集
          // 通过创建两次子集实现突出显示当前分部分项，
          // 第一个子集几乎全透明，第二个子集用来显示当前分部分项工程关联的模型
          this.preIfcModel.removeFromParent();
          this.ifcLoader.ifcManager.createSubset({
            modelID: this.preIfcModel.modelID,
            ids: this.getAllIds(),
            applyBVH: true, // BVH算法来加速光线投射并支持快速的3D空间查询
            material: this.translucenceMat,// 半透明
            scene: this.scene,
            removePrevious: true,
            customID: 'subset-all',
          });

          // subsetExpressIds = [...visualScheduleStartedIds, ...visualScheduleCompletedIds,...visualScheduleNotStartedIds]
          // 已开工
          if (visualScheduleStartedIds.length > 0) {
            this.ifcLoader.ifcManager.createSubset({
              modelID: this.preIfcModel.modelID,
              ids: visualScheduleStartedIds,
              applyBVH: true,// BVH算法来加速光线投射并支持快速的3D空间查询
              material: this.visualScheduleStartMat,
              scene: this.scene,
              removePrevious: true,
              customID: 'subset-visual-schedule-started',
            });
          }
          // 已完工
          if (visualScheduleCompletedIds.length > 0) {
            this.ifcLoader.ifcManager.createSubset({
              modelID: this.preIfcModel.modelID,
              ids: visualScheduleCompletedIds,
              applyBVH: true,// BVH算法来加速光线投射并支持快速的3D空间查询
              material: this.visualScheduleCompletedMat,
              scene: this.scene,
              removePrevious: true,
              customID: 'subset-visual-schedule-completed',
            });
          }
        }
        else {
          this.scene.add(this.mesh || ifcModel); // 将IFC模型添加到Three.js场景中
        }

        if (typeof callback == 'function') {
          callback()
        }
      }
    );
  }

}









